import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/all.css'; // Import jQuery UI CSS

import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.js';
import jszip from 'jszip';
import pdfmake from 'pdfmake';

window.JSZip = jszip;
window.pdfMake = pdfmake;

require("channels")
import "controllers";