import consumer from "./consumer"
$(function () {
    $('[data-channel-subscribe="room"]').each(function (index, element) {
        console.log("room-detected");
        var $element = $(element)
        var $room_id = $element.data('room')

        console.log($room_id);

        consumer.subscriptions.create(
            {
                channel: "RoomChannel",
                room: $room_id
            },
            {
                connected() {
                    console.log("Connected to channel");
                },

                received: function (data) {
                    if ($('#messages').data('user') == data.user_id) {
                        data.content = data.content.replace(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim, '<a href="$1" class = "link-color-chat-me" target="_blank">$1 <i class = "fa fa-external-link"></i></a>');
                        data.content = data.content.replace(/(^|[^\/])(www\.[\S]+(\b|$))/gim, '$1<a href="http://$2" class = "link-color-chat-me" target="_blank">$2 <i class = "fa fa-external-link"></i></a>');
                        $('#messages').append('<p class = "from-me" style = "display: none;" id = "new-message-block">' + data.content + '</p>');
                        $('#new-message-block').fadeIn(100);
                        $('#new-message-block').removeAttr('id');
                        var msg_block = $('#messages');
                        msg_block.scrollTop(msg_block.prop("scrollHeight"));
                    } else {
                        let formattedContent = data.content.replace(/(https?:\/\/[\S]+)/g, '<a href="$1" class = "link-color-chat-them" target="_blank">$1 <i class = "fa fa-external-link"></i></a>');
                        $('#messages').append('<p class = "from-them">' + formattedContent + '</p>');
                        var msg_block = $('#messages');
                        msg_block.scrollTop(msg_block.prop("scrollHeight"));
                    }
                }
            }
        );
    });
    $("#msg-field").val("");
});